import { Button, Checkbox, Form, Input, Row } from 'antd'
import Link from 'next/link'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
function LoginForm({ loading, t, onSubmit }) {
  const router = useRouter()
  const [form] = Form.useForm()
  const { i18n } = useTranslation()

  return (
    <Form
      layout={'vertical'}
      form={form}
      name="basic"
      className={'form-wrapper'}
      onFinish={(values) => values?.username && values?.password?.length >= 6 && onSubmit(values)}
      onFinishFailed={() => {}}
    >
      <div className="login-form">
        <Row className="form-group">
          <Form.Item
            className={'form-item'}
            label={<span className="title">{t('username')}</span>}
            name="username"
          >
            <Input />
          </Form.Item>
          <Form.Item initialValue={true} name="remember" valuePropName="checked" noStyle>
            <Checkbox className="remember-wrapper">{t('remember')}</Checkbox>
          </Form.Item>
        </Row>
        <Row className="form-group" style={{ justifyContent: 'flex-start' }}>
          <Form.Item
            className={'form-item'}
            name="password"
            label={<span className="title">{t('password')}</span>}
          >
            <Input type="password" />
          </Form.Item>
          <Link href="/forgot-password">{t('forgotPassword')}</Link>
        </Row>
        <Row className="form-group" style={{ flexDirection: 'row', gap: 10, alignItems: 'center' }}>
          <Button
            loading={loading}
            htmlType={'submit'}
            className="login-btn"
            type="default"
            style={{ height: 24, paddingLeft: 6, paddingRight: 6 }}
          >
            {t('login')}
          </Button>
          <Button
            onClick={() => router.push(`/login`)}
            className="register-btn"
            style={{ border: 'none', height: 24, paddingLeft: 6, paddingRight: 6 }}
          >
            {t('register')}
          </Button>
        </Row>
      </div>
    </Form>
  )
}

LoginForm.propTypes = {
  t: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({ loading: state.login?.loading })

export default connect(mapStateToProps)(React.memo(LoginForm))
