import { faCaretUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import Svg from '~/components/Svg'
import { LoginForm } from '~/components/UserForm'
import { useTranslation, withTranslation } from '~/i18n'
import LogoVF from '~/public/assets/jpeg/vietfish_logo.jpeg'
import { getWhoamiAction, loginAction, setAccountAction } from '~/redux/actions/Authentication'
import { getProducerAction } from '~/redux/actions/Producer'
import { DTRACK, FOODMAP, VIETFISH } from '~/utils/constants'
// import './styles.scss'

export const LoginHeader = ({ t, platform, setAccount, login, getWhoami, getProducer }) => {
  const router = useRouter()
  const { i18n } = useTranslation()
  const [loginFailure, setLoginFailure] = useState(false)
  const [showLogin, setShowLogin] = useState(false)

  const onLogin = (values) => {
    setAccount(values)
    login(
      values,
      () => {
        getWhoami((user) => {
          if (!user?.roles?.length > 0) {
            getProducer()
          }
        })
        router.push('/')
      },
      () => setLoginFailure(true),
    )
  }

  const renderLogo = () => {
    const logos = {
      [DTRACK]: <Svg name="logo" height={'100%'} width={167} />,
      [VIETFISH]: <img src={LogoVF?.src} alt="logo" />,
      [FOODMAP]: <Svg name="insight-logo" height={49} width={300} />,
    }

    return logos?.[platform] || logos?.[DTRACK]
  }

  return (
    <div className="login-header-wrapper">
      <div className="login-header container">
        <div className="logo-wrapper">
          <div
            style={{ display: 'grid', placeItems: 'center' }}
            onClick={() => router.push(`/${i18n.language}/`)}
          >
            {renderLogo()}
          </div>
          {platform !== FOODMAP && platform !== DTRACK && (
            <div className="link-news">
              <Link href={`/${i18n.language}/services`}>{t('services')}</Link>
            </div>
          )}
          {platform === DTRACK && (
            <>
              <div className="link-news">
                <Link href={`/about-us`}>Về chúng tôi</Link>
              </div>
              <div className="link-news">
                <Link href={`/${i18n.language}/services/client`}>Khách hàng & Đối tác</Link>
              </div>
              <div className="link-news">
                <Link href="/news">Tin tức</Link>
              </div>
            </>
          )}
          {platform === VIETFISH && (
            <div className="link-news">
              <Link href={`${i18n.language}/register-department`}>{t('register-department')}</Link>
            </div>
          )}
        </div>
        <div className="responsive-login-wrapper">
          <div className="responsive-login-button" onClick={() => setShowLogin(!showLogin)}>
            {showLogin ? <FontAwesomeIcon icon={faCaretUp} style={{ fontSize: 20 }} /> : t('login')}
          </div>
        </div>
        <div
          className={`login-section ${loginFailure ? 'login-section-failure' : ''} ${
            showLogin ? 'show' : 'hide'
          }`}
        >
          <LoginForm t={t} onSubmit={onLogin} />
          {loginFailure && <div className="error-text">{t('loginFailureErr')}</div>}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  platform: state.layout?.platform?.platform,
})

const mapDispatchToProps = (dispatch) => ({
  setAccount: (payload) => dispatch(setAccountAction(payload)),
  getWhoami: (actionSuccess) => dispatch(getWhoamiAction(actionSuccess)),
  getProducer: () => dispatch(getProducerAction()),
  login: (payload, actionSuccess, actionFailure) =>
    dispatch(loginAction(payload, actionSuccess, actionFailure)),
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation('login'),
)(LoginHeader)
